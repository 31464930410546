var gmaps = require('google-maps');
gmaps.KEY = 'AIzaSyArE3qsTJucfm-EUp4XblvV8CnwlqYtlus';

if ($('.js-maps').length) {
    gmaps.load(function(google) {
        var el = document.querySelector('.js-maps');
        var lat = 51.136575;
        var long = 2.704943;
        var center = new google.maps.LatLng(lat, long);

        var icon = {
            url: '/bundles/sitefrontend/img/pin.png',
            scaledSize: new google.maps.Size(45, 45)
        };

        var options = {
            zoom: 15,
            center: center,
						scrollwheel: false,
            styles: [
              {
                stylers: [
                  {
                    'saturation': '-100'
                  }
                ]
              }
            ]
						};

        var map = new google.maps.Map(el, options);

        google.maps.event.addDomListener(window, "resize", function() {
            var center = map.getCenter();
            google.maps.event.trigger(map, "resize");
            map.setCenter(center);
        });

        var marker = new google.maps.Marker({
            position: center,
            map: map,
            icon: icon
        });
    });
}
