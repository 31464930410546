var slick = require('slick-carousel');

$('.js-hero-slider').slick({
  fade: true,
  arrows: false
});

$('.js-gallery-slider').slick({
  arrows: false,
  slidesToShow: 4,
  infinite: true,
  autoplay: true
});
