var raty = require('raty-js');
var moment = require('moment');

(function () {

var ReviewController = (function(){

  var fetchReviews = Routing.generate('site_frontend_ajax_fetch_reviews');

  var Review = function(name, date, score, body){
    this.name = name;
    this.date = date;
    this.score = score;
    this.body = body;
  };

  return {

    getReviews: function(callback){
      $.ajax({
         url: fetchReviews,
         type: "GET",
         dataType: 'json',
         success: callback
     });
   },

   createReviews: function(data){
     var reviews = [];
     for (var i = 0; i < data.length; i++) {
       var newReview = new Review(data[i].Naam, data[i].Creationdate, data[i].Score, data[i].Opmerkingen);
       reviews.push(newReview);
     }
     return reviews;
   },

   calculateAvg: function(reviews){
     var reviewScores = {
       totalReviews: reviews.length,
       total: 0,
       average: 0
     }
     for (var i = 0; i < reviews.length; i++) {
       reviewScores.total += reviews[i].score;
     }
     reviewScores.average = reviewScores.total / reviews.length;

     return reviewScores;
   }

  }

})();

var UIController = (function(){

  var list = document.querySelector('.js-review-list');

  // var htmlTemplate = '<li><div class="reviewed- item"><div class="reviewed-item-header"><h2>{{name}} <span class="datum">({{date}})</span></h2><div class="rated" data-score="{{score}}"></div></div><div class="review-text"><p>{{body}}</p></div></div></li>';
  var htmlTemplate = '<article class="review">';
      htmlTemplate += '<h2 class="review__author">{{name}}</h2>';
      htmlTemplate += '<span class="review__score" data-score="{{score}}"></span>';
      htmlTemplate += '<span class="review__date">{{date}}</span>';
      htmlTemplate += '<p class="review__body">{{body}}</p>';
      htmlTemplate += '</article>';

  function formatDate(date){

    date = new Date(date);
    date = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();

    return date;
  }

  function initRaty(el){

    $(el).raty({
        half: true,
        readOnly: true,
        size: 24,
        path: '/bundles/sitefrontend/img/rating',
        starHalf: 'star-half.svg',
        starOff: 'star-off.svg',
        starOn: 'star-on.svg',
        score: function () {
            return $(this).attr('data-score') / 2;
        }
    });
  }

  return {

    displayReviews: function(reviews){
      for (var i = 0; i < reviews.length; i++) {
        var newHtmlTemplate = htmlTemplate.replace('{{name}}', reviews[i].name);
            newHtmlTemplate = newHtmlTemplate.replace('{{date}}', moment(new Date(reviews[i].date)).fromNow());
            newHtmlTemplate = newHtmlTemplate.replace('{{score}}', reviews[i].score);
            newHtmlTemplate = newHtmlTemplate.replace('{{body}}', reviews[i].body);
        list.insertAdjacentHTML('beforeend', newHtmlTemplate);

      }
      initRaty('.review__score');
      var loader = document.querySelector('.loading');
      loader.parentNode.removeChild(loader);
    },

    displayAverage: function(avg){
      document.querySelector('.review__average').dataset.score = avg.average;
      document.querySelector('.review__total').textContent = avg.totalReviews + ' Reviews';
      initRaty('.review__average');
    }

  }

})();

var LanguageController = (function(){

  var lang = {
    nl: {
      bad: 'vreselijk',
      poor: 'matig',
      regular: 'gemiddeld',
      good: 'heel goed',
      amazing: 'uitstekend',
      default: 'Klik om te beoordelen'
    },
    fr: {
      bad: 'mal',
      poor: 'pauvre',
      regular: 'ordinaire',
      good: 'bien',
      amazing: 'magnifique',
      default: 'cliquez pour évaluer'
    },
    de: {
      bad: 'schlecht',
      poor: 'mäßig',
      regular: 'durchschnitt',
      good: 'gut',
      amazing: 'ausgezeichnet',
      default: 'klicken um zu bewerten'
    },
    en: {
      bad: 'bad',
      poor: 'poor',
      regular: 'regular',
      good: 'good',
      amazing: 'amazing',
      default: 'click to rate'
    }
  }

  var currentLang = $('html').attr('lang');

  return {
     translateRating: function(rating){
      return lang[currentLang][rating];
    }
  }

})();

var RatingController = (function(LanguageController){

  $('.rating__caption').text(LanguageController.translateRating('default'));

  $('.rating__userscore').raty({
      path: '/bundles/sitefrontend/img/rating',
      starHalf: 'star-half.svg',
      starOff: 'star-off.svg',
      starOn: 'star-on.svg',
      size: 24,
      width: false,
      half: false,
      hints: ['bad', 'poor', 'regular', 'good', 'amazing']
  });

  var img = $('.rating__userscore img');

  img.on('mouseenter', function(){
    $('.rating__caption').text(LanguageController.translateRating($(this).attr('title')));
  });

  img.on('click', function(){
    img.removeClass('selected');
    $(this).addClass('selected');
  });

  $('.rating__userscore').on('mouseleave', function(){
    if ($('.selected').length){
      $('.rating__caption').text(LanguageController.translateRating($('.selected').attr('title')));
    } else {
      $('.rating__caption').text(LanguageController.translateRating('default'));
    }
  });

})(LanguageController);

var Controller = (function(ReviewController, UIController){

  function reviewCallback(data){
    if(data){
      var allReviews = ReviewController.createReviews(data.reviews);
      UIController.displayReviews(allReviews);

      var avg = ReviewController.calculateAvg(allReviews);
      UIController.displayAverage(avg);
    }
  }

  return {
    init: function(){
      ReviewController.getReviews(reviewCallback);
    }
  }

})(ReviewController, UIController);

if($('.js-review-list').length){
  Controller.init();
}

})();
